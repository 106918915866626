import { getRequest, patchRequest, postRequest, deleteRequest } from "../base"

export const getFirms = (token, { page = 1, per_page = 100, sortBy = "", sortDesc = false, approvalState = undefined, exceptIds = [], query } = {}) => {
    let params = { page, per_page }
    if (approvalState) {
        params["approval_state"] = approvalState
    }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'created_at'
        params["direction"] = 'desc'
    }
    if (query) {
        params["query"] = query
    }
    if(!!exceptIds.length){
        params["except_ids"] = exceptIds
    }
    return getRequest("/admin/firms", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getFirm = (token, { id }) => {
    return getRequest(`/admin/firms/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveFirm = (token, firm) => {
    if (firm.id) {
        let { id, ...firmWithoutId } = firm
        return patchRequest(`/admin/firms/${ id }`, {
            firm_profile: firmWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/firms", {
            firm_profile: firm
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteFirm = (token, firm) => {
    return deleteRequest(`/admin/firms/${ firm.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const approvalStates = (token) => {
    return getRequest("/admin/firms/approval_states", null, {
        "Authorization": `Bearer ${ token }`
    })
}
