<template>
    <div>
        <div class="d-inline-flex">
            <h1 v-if="!$route.params.id">New Manufacturer's Profile</h1>
            <h1 v-else>Edit Manufacturer's Profile</h1>
        </div>
        <div class="d-inline-flex float-right sticky-top" style="top:90px;">
            <b-button
                size="sm"
                variant="primary"
                class="px-5 float-right"
                @click="onSave"
            >
                Save
            </b-button>
        </div>
        
        <b-row v-if="isReasonSubmitted && originalAttributes.comment" class="sub-division mb-3">
            <b-col class="text-center text-muted">
                {{originalAttributes.comment}}
            </b-col>
        </b-row>
        <b-row class="pt-2 pb-4" v-if="$route.params.id">
            <b-button-group class="mx-1">
                <b-button
                variant="danger"
                @click="onClickDelete"
                >
                Delete
                </b-button>
                <b-button
                v-if="originalAttributes.profile_state != 'published'"
                variant="success"
                @click="onClickApprove"
                >
                Approve
                </b-button>
                <b-button
                v-if="originalAttributes.profile_state != 'published'"
                variant="danger"
                @click="originalAttributes.profile_state == 'application' || originalAttributes.profile_state == 'submitted' && $bvModal.show('ask-deny-reason')"
                >
                Deny
                </b-button>
                <b-button
                variant="warning"
                @click="onClickSuspend"
                >
                Suspend
                </b-button>
                <b-button
                    target="_blank"
                    :to="{ name: 'product-page', params: { id: $route.params.id } }"
                >
                    Preview Profile
                </b-button>
                <b-button
                variant="warning"
                @click="onClickEmailAdmin"
                >
                Email Admin
                </b-button>
            </b-button-group>
        </b-row>

        <!-- <b-row>
            <b-col cols="12" md="4">
                <b-img
                    :src="attributes.logo_path"
                    class="mb-3"
                    alt="Image 1"
                    blank-color="#aaa"
                    thumbnail
                    rounded
                    fluid
                />
            </b-col>

            <b-col cols="12" md="8">
                <b-form-group
                    id="manufacturer_logo_fieldset"
                    label="Company Logo"
                    label-for="manufacturer_logo"
                    :invalid-feedback="logoError"
                    :state="logoState"
                >
                    <b-form-file
                        id="manufacturer_logo"
                        ref="logo-file-input"
                        :state="logoState"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        accept="image/jpeg, image/png, image/gif"
                        @input="onLogoUpload"
                    />

                    <b-button
                        class="mr-2 mt-2"
                        @click="onResetLogo"
                    >
                        Reset
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row> -->
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_company_name_fieldset"
                    label="Company Name"
                    label-for="manufacturer_company_name"
                    :invalid-feedback="companyNameError"
                    :state="companyNameState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_company_name"
                        v-model="attributes.company_name"
                        :state="companyNameState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_profile_type_fieldset"
                    label="Type"
                    label-for="manufacturer_profile_type"
                    :invalid-feedback="profileTypeError"
                    :state="profileTypeState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_profile_type"
                        v-model="attributes.profile_type"
                        :options="profileTypeOptions"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_uid_fieldset"
                    label="ID No."
                    label-for="manufacturer_uid"
                    :invalid-feedback="uidError"
                    :state="uidState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_uid"
                        v-model="attributes.uid"
                        :state="uidState"
                        type="number"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_profile_state_fieldset"
                    label="Status"
                    label-for="manufacturer_profile_state"
                    :invalid-feedback="profileStateError"
                    :state="profileStateState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_profile_state"
                        v-model="attributes.profile_state"
                        :options="profileStateOptions"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_site_fieldset"
                    label="URL"
                    label-for="manufacturer_site"
                    :invalid-feedback="siteError"
                    :state="siteState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_site"
                        v-model="attributes.site"
                        :state="siteState"
                        type="url"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_url_on_profile_fieldset"
                    label="URL on Profile"
                    label-for="manufacturer_url_on_profile"
                    :invalid-feedback="urlOnProfileError"
                    :state="urlOnProfileState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_url_on_profile"
                        v-model="attributes.url_on_profile"
                        :state="urlOnProfileState"
                        type="url"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_phone_fieldset"
                    label="Phone Number"
                    label-for="manufacturer_phone"
                    description="Phone Number (123.456.7890)"
                    :invalid-feedback="phoneError"
                    :state="phoneState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_phone"
                        v-model="attributes.phone"
                        :state="phoneState"
                        type="tel"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_email_fieldset"
                    label="Company Email"
                    label-for="manufacturer_email"
                    :invalid-feedback="emailError"
                    :state="emailState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_email"
                        v-model="attributes.email"
                        :state="emailState"
                        type="email"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_country_fieldset"
                    label="Country"
                    label-for="manufacturer_country"
                    :invalid-feedback="countryError"
                    :state="countryState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_country"
                        v-model="attributes.country"
                        :state="countryState"
                        :options="countries"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                Enter Country
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_state_fieldset"
                    label="State / Province"
                    label-for="manufacturer_state"
                    :invalid-feedback="stateError"
                    :state="stateState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_state"
                        v-model="attributes.state"
                        :options="states"
                        :state="stateState"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                Enter State / Province
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_city_fieldset"
                    label="City"
                    label-for="manufacturer_city"
                    :invalid-feedback="cityError"
                    :state="cityState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_city"
                        v-model="attributes.city"
                        :state="cityState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_zip_fieldset"
                    label="Zip / Postal Code"
                    label-for="manufacturer_zip"
                    :invalid-feedback="zipError"
                    :state="zipState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_zip"
                        v-model="attributes.zip"
                        :state="zipState"
                        type="text"
                        class="text-left"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_address_fieldset"
                    label="Address"
                    label-for="manufacturer_address"
                    :invalid-feedback="addressError"
                    :state="addressState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_address"
                        v-model="attributes.address"
                        :state="addressState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
<!--                <b-form-group
                    id="manufacturer_employees_fieldset"
                    label="Employees"
                    label-for="manufacturer_employees"
                    :invalid-feedback="employeesError"
                    :state="employeesState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_employees"
                        v-model="attributes.employees"
                        :state="employeesState"
                        :options="employees"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                Employees
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group> -->

                <b-form-group
                    id="manufacturer_employees_fieldset"
                    label="Employees"
                    label-for="manufacturer_employees"
                    :invalid-feedback="employeesError"
                    :state="employeesState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_employees"
                        v-model="attributes.employees"
                        :state="employeesState"
                        placeholder="Enter number of employees"
                    >
                    </b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_market_fieldset"
                    label="Markets"
                    label-for="manufacturer_market"
                    :invalid-feedback="marketError"
                    :state="marketState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_market"
                        v-model="attributes.market"
                        :options="markets"
                        :state="marketState"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                Enter Market
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_year_founded_fieldset"
                    label="Year Founded"
                    label-for="manufacturer_year_founded"
                    :invalid-feedback="yearFoundedError"
                    :state="yearFoundedState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_year_founded"
                        v-model="attributes.year_founded"
                        :state="yearFoundedState"
                        type="number"
                        class="text-left"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_statement_fieldset"
                    label="Statement (75 words or less)"
                    label-for="manufacturer_statement"
                    :invalid-feedback="statementError"
                    :state="statementState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_statement"
                        v-model="attributes.statement"
                        :state="statementState"
                        placeholder="Enter statement..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_principals_fieldset"
                    label="Principals"
                    label-for="manufacturer_principals"
                    :invalid-feedback="principalsError"
                    :state="principalsState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_principals"
                        v-model="attributes.principals"
                        :state="principalsState"
                        placeholder="Enter principals..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_applications_fieldset"
                    label="Applications"
                    label-for="manufacturer_applications"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-select
                        id="manufacturer_applications"
                        v-model="attributes.applications"
                        :options="applications"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                Enter Applications
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_linkedin_link_fieldset"
                    label="LinkedIn Link"
                    label-for="manufacturer_linkedin_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_linkedin_link"
                        v-model="attributes.linkedin_link"
                        placeholder="Enter LinkedIn Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_twitter_link_fieldset"
                    label="X Link"
                    label-for="manufacturer_twitter_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_twitter_link"
                        v-model="attributes.twitter_link"
                        placeholder="Enter X Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_facebook_link_fieldset"
                    label="Facebook Link"
                    label-for="manufacturer_facebook_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_facebook_link"
                        v-model="attributes.facebook_link"
                        placeholder="Enter Facebook Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_instagram_link_fieldset"
                    label="Instagram Link"
                    label-for="manufacturer_instagram_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_instagram_link"
                        v-model="attributes.instagram_link"
                        placeholder="Enter Instagram Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_youtube_link_fieldset"
                    label="Youtube Link"
                    label-for="manufacturer_youtube_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_youtube_link"
                        v-model="attributes.youtube_link"
                        placeholder="Enter Youtube Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_pinterest_link_fieldset"
                    label="Pinterest Link"
                    label-for="manufacturer_pinterest_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_pinterest_link"
                        v-model="attributes.pinterest_link"
                        placeholder="Enter Pinterest Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

<!--         <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_catalogue_email_fieldset"
                    label="Catalogue Email"
                    label-for="manufacturer_catalogue_email"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_catalogue_email"
                        v-model="attributes.catalogue_email"
                        type="email"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_sample_email_fieldset"
                    label="Sample Email"
                    label-for="manufacturer_sample_email"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_sample_email"
                        v-model="attributes.sample_email"
                        type="email"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_quotes_email_fieldset"
                    label="Quotes Email"
                    label-for="manufacturer_quotes_email"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_quotes_email"
                        v-model="attributes.quotes_email"
                        type="email"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row> -->

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_request_info_link_fieldset"
                    label="Request Info Email"
                    label-for="manufacturer_request_info_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_request_info_link"
                        v-model="attributes.request_info_link"
                        placeholder="Enter Request Info Email"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_request_samples_link_fieldset"
                    label="Request Samples Email"
                    label-for="manufacturer_request_samples_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_request_samples_link"
                        v-model="attributes.request_samples_link"
                        placeholder="Enter Request Samples Email"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_request_quote_link_fieldset"
                    label="Request Quote Email"
                    label-for="manufacturer_request_quote_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_request_quote_link"
                        v-model="attributes.request_quote_link"
                        placeholder="Enter Request Quote Email"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_products_link_fieldset"
                    label="Products Link"
                    label-for="manufacturer_products_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_products_link"
                        v-model="attributes.products_link"
                        placeholder="Enter Products Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_catalogs_link_fieldset"
                    label="Catalogs Link"
                    label-for="manufacturer_catalogs_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_catalogs_link"
                        v-model="attributes.catalogs_link"
                        placeholder="Enter Catalogs Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_videos_link_fieldset"
                    label="Videos Link"
                    label-for="manufacturer_videos_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_videos_link"
                        v-model="attributes.videos_link"
                        placeholder="Enter Videos Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_specifications_link_fieldset"
                    label="Specifications Link"
                    label-for="manufacturer_specifications_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_specifications_link"
                        v-model="attributes.specifications_link"
                        placeholder="Enter Specifications Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_leed_info_link_fieldset"
                    label="LEED Info Link"
                    label-for="manufacturer_leed_info_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_leed_info_link"
                        v-model="attributes.leed_info_link"
                        placeholder="Enter LEED Info Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_cad_link_fieldset"
                    label="CAD Link"
                    label-for="manufacturer_cad_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_cad_link"
                        v-model="attributes.cad_link"
                        placeholder="Enter CAD Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_bim_link_fieldset"
                    label="BIM Link"
                    label-for="manufacturer_bim_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_bim_link"
                        v-model="attributes.bim_link"
                        placeholder="Enter BIM Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_revit_link_fieldset"
                    label="Revit Link"
                    label-for="manufacturer_revit_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_revit_link"
                        v-model="attributes.revit_link"
                        placeholder="Enter Revit Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_ceu_link_fieldset"
                    label="CEU Link"
                    label-for="manufacturer_ceu_link"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_ceu_link"
                        v-model="attributes.ceu_link"
                        placeholder="Enter CEU Link"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

<!--         <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_rep_link_fieldset"
                    label="Find a Rep Link"
                    label-for="manufacturer_rep_link"
                    :invalid-feedback="FindARepError"
                    :state="FindARepState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_rep_link"
                        v-model="attributes.rep_link"
                        :state="FindARepState"
                        type="text"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row> -->

<!--         <b-row>
            <b-col>
                <b-form-group
                    label="Quick Links"
                >
                    <b-container>
                        <b-row
                            v-for="link in attributes.quick_links"
                            :key="link.type"
                            class="link_row"
                        >
                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_quick_links_${link.type}_type`"
                                    :value="quickLinks[link.type]"
                                    type="text"
                                    readonly
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="8">
                                <b-form-input
                                    :id="`manufacturer_quick_links_${link.type}_url`"
                                    v-model="link.url"
                                    placeholder="Enter URL..."
                                    type="url"
                                    trim
                                />
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
            </b-col>
        </b-row> -->

<!--         <b-row class="mb-3">
            <b-col cols="12" md="12">
                <b-form-checkbox
                    id="manufacturer_green_products"
                    v-model="attributes.green_products"
                    name="attributes[green_products]"
                >
                    Green Products
                </b-form-checkbox>
            </b-col>
        </b-row> -->

<!--    <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_div_fieldset"
                    label="CSI Division"
                    label-for="manufacturer_div"
                    :invalid-feedback="divError"
                    :state="divState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <multiselect
                        v-model="attributes.div"
                        label="text"
                        track-by="value"
                        placeholder="Search By CSI Division"
                        open-direction="bottom"
                        :options="csi_divisions"
                        :multiple="false"
                        :searchable="true"
                        :internal-search="false"
                        :loading="isLoadingPrimaryTypeCodeCsiIndexEntries"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="count => {`and ${ count } other entries`}"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindPrimaryTypeCodeCsiIndexEntries"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No divisions were found. Consider changing the search query.
                        </span>
                    </multiselect>
                </b-form-group>
            </b-col>
        </b-row> -->

                <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_primary_csi_division_fieldset"
                    label="Primary CSI Division"
                    label-for="manufacturer_primary_csi_division"
                    :state="primaryCsiDivisionState"
                    :invalid-feedback="primaryCsiDivisionError"
                    label-cols-md="3"
                    label-align-md="left"
                    >
                    <multiselect
                        v-model="attributes.primary_csi_division"
                        label="text"
                        track-by="value"
                        placeholder="Select CSI Division"
                        open-direction="bottom"
                        :options="csiIndexEntryOptions"
                        :multiple="false"
                        :searchable="true"
                        :loading="isLoadingCsiIndexEntries"
                        :internal-search="false"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :options-limit="300"
                        :limit="8"
                        :limit-text="limitTextCsiIndexEntries"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        @search-change="asyncFindCsiIndexEntries"
                    >
                        <span
                            slot="noResult"
                        >
                            Oops! No elements found. Consider changing the search query.
                        </span>
                    </multiselect>
                </b-form-group>
           </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_green_statement_fieldset"
                    label="Green Statement"
                    label-for="manufacturer_green_statement"
                    :invalid-feedback="greenStatementError"
                    :state="greenStatementState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_green_statement"
                        v-model="attributes.green_statement"
                        :state="greenStatementState"
                        placeholder="Enter green statement..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    label="Green Statement Links"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-container>
                        <b-row
                            v-for="(link, index) in attributes.green_statement_links"
                            :key="index"
                            class="link_row"
                        >
                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_green_statement_links_${index}_title`"
                                    v-model="link.title"
                                    placeholder="Enter title..."
                                    type="text"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_green_statement_links_${index}_url`"
                                    v-model="link.url"
                                    placeholder="Enter URL..."
                                    type="url"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onGreenStatementLinkUp(index)"
                                >
                                    Up
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onGreenStatementLinkDown(index)"
                                >
                                    Down
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="onGreenStatementLinkAdd(index)"
                                >
                                    Add
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="onGreenStatementLinkRemove(index)"
                                >
                                    Remove
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_products_fieldset"
                    label="Products Statement"
                    label-for="manufacturer_products"
                    :invalid-feedback="productsError"
                    :state="productsState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_products"
                        v-model="attributes.products"
                        :state="productsState"
                        placeholder="Enter products..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    label="Products Links"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-container>
                        <b-row
                            v-for="(link, index) in attributes.product_links"
                            :key="index"
                            class="link_row"
                        >
                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_product_links_${index}_title`"
                                    v-model="link.title"
                                    placeholder="Enter title..."
                                    type="text"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_product_links_${index}_url`"
                                    v-model="link.url"
                                    placeholder="Enter URL..."
                                    type="url"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onProductLinkUp(index)"
                                >
                                    Up
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onProductLinkDown(index)"
                                >
                                    Down
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="onProductLinkAdd(index)"
                                >
                                    Add
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="onProductLinkRemove(index)"
                                >
                                    Remove
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_latest_products_fieldset"
                    label="Latest Products"
                    label-for="manufacturer_latest_products"
                    :invalid-feedback="latestProductsError"
                    :state="latestProductsState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_latest_products"
                        v-model="attributes.latest_products"
                        :state="latestProductsState"
                        placeholder="Enter latest products..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    label="Latest Products Links"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-container>
                        <b-row
                            v-for="(link, index) in attributes.latest_products_links"
                            :key="index"
                            class="link_row"
                        >
                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_latest_products_links_${index}_title`"
                                    v-model="link.title"
                                    placeholder="Enter title..."
                                    type="text"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-form-input
                                    :id="`manufacturer_latest_products_links_${index}_url`"
                                    v-model="link.url"
                                    placeholder="Enter URL..."
                                    type="url"
                                    trim
                                />
                            </b-col>

                            <b-col cols="12" md="4">
                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onLatestProductLinkUp(index)"
                                >
                                    Up
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="onLatestProductLinkDown(index)"
                                >
                                    Down
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-success"
                                    @click="onLatestProductLinkAdd(index)"
                                >
                                    Add
                                </b-button>

                                <b-button
                                    pill
                                    size="sm"
                                    variant="outline-danger"
                                    @click="onLatestProductLinkRemove(index)"
                                >
                                    Remove
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_showrooms_url_fieldset"
                    label="Showrooms Url"
                    label-for="manufacturer_showrooms_url"
                    :invalid-feedback="showroomsUrlError"
                    :state="showroomsUrlState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-input
                        id="manufacturer_showrooms_url"
                        v-model="attributes.showrooms_url"
                        :state="showroomsUrlState"
                        type="url"
                        trim
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_showrooms_note_fieldset"
                    label="Showrooms Note"
                    label-for="manufacturer_showrooms_note"
                    :invalid-feedback="showroomsNoteError"
                    :state="showroomsNoteState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-textarea
                        id="manufacturer_showrooms_note"
                        v-model="attributes.showrooms_note"
                        :state="showroomsNoteState"
                        placeholder="Enter showrooms note..."
                        rows="3"
                        max-rows="6"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
<!--                 <b-row>
                    <b-col>
                        <b-form-group
                            id="manufacturer_csi_sub_divisions_fieldset"
                            label="CSI Sub-Divisions"
                            label-for="manufacturer_csi_sub_divisions"
                            label-cols-md="3"
                            label-align-md="left"
                        >
                            <b-form-input
                                id="manufacturer_csi_sub_divisions"
                                :value="csiIndexQuery"
                                type="text"
                                debounce="1500"
                                placeholder="Enter word to start search..."
                                trim
                                @input="onCsiIndexQueryChanged"
                            />
                        </b-form-group>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col>
                        <b-input-group>
                            <multiselect
                                :value="csiIndexQuery"
                                label="text"
                                track-by="value"
                                :placeholder="`Select Divisions`"
                                open-direction="bottom"
                                :options="csiIndexEntryOptions"
                                :multiple="false"
                                :searchable="true"
                                :loading="isLoadingCsiIndexEntries"
                                :internal-search="false"
                                :clear-on-select="true"
                                :close-on-select="true"
                                :options-limit="300"
                                :limit="8"
                                :limit-text="limitTextCsiIndexEntries"
                                :max-height="600"
                                :show-no-results="false"
                                :hide-selected="false"
                                @search-change="asyncFindCsiIndexEntries"
                                @input="onSelectCsiIndexEntry"
                            >
                                <span
                                    slot="noResult"
                                >
                                    Oops! No elements found. Consider changing the search query.
                                </span>
                            </multiselect>
                        </b-input-group>
                    </b-col>
                </b-row>
                        <b-row class="mt-2">
                            <b-col style="width: 100%;">
                                <div class="accordion" vertical role="tablist">
                                    <b-card v-for="(division, index) in csiIndexEntryNestedOptions" v-bind:key="'index-parent-'+division.parent.split(' ').join('_')" no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                             <!-- <b-button-toolbar key-nav aria-label="Toolbar with button groups"> -->
                                                 <b-button-group class="mx-1 w-100">
                                                    <b-button v-b-toggle="division.parent.split(' ').join('_')" role="tab" style="cursor: pointer; width: 90%;" variant="info">
                                                        <span class="d-flex justify-content-start">
                                                            <span class="when-opened">
                                                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" height="20px" width="20px"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="white" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                                                             </span>
                                                             <span class="when-closed">
                                                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="20px" width="20px"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="white" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>
                                                             </span>
                                                             <span style="margin-left: 15px;">{{division.parent}}</span>
                                                        </span>
                                                    </b-button>
                                                    <b-button style="width:10%;" @click.prevent.stop="onRemoveParentCsiEntry(division.parent_object)">
                                                        X
                                                    </b-button>
                                                </b-button-group>
                                            <!-- </b-button-toolbar> -->
                                        </b-card-header>
                                        <b-collapse :id="division.parent.split(' ').join('_')" aria-expanded="false" role="tabpanel" accordion="my-accordion">
                                            <b-card-body>
                                                <div v-for="(children, c_index) in division.children" v-bind:key="'childen-'+children.code.split(' ').join('_')">
                                                    <b-row>
                                                        <b-col
                                                            cols="6"
                                                            class="d-flex align-items-center"
                                                        >
                                                            <b-form-checkbox
                                                                v-model="children.applied"
                                                                :value="true"
                                                                :unchecked-value="false"
                                                                @input="checkMeNextOptions"
                                                                :disabled="children.disabled"
                                                            >
                                                                {{ children.code }}
                                                                {{ children.title }}
                                                            </b-form-checkbox>
                                                        </b-col>

                                                        <b-col v-if="children.applied && !children.image_filename" cols="3" class="pl-4">
                                                            <b-form-file
                                                                v-model="children.image_picker"
                                                                placeholder="Choose File"
                                                                browse-text="Choose File"
                                                                drop-placeholder=""
                                                                class="compact-file-selector"
                                                                accept="image/jpeg, image/png, image/gif"
                                                                @input="onUpload(children, 'image', $event)"
                                                            />
                                                        </b-col>
                                                        
                                                        <b-col v-if="children.applied" cols="3" class="d-flex align-items-center justify-content-between w-100">
                                                            <span
                                                                class="file-selector-label text-nowrap"
                                                            >
                                                                {{children.image_filename || 'Attach a photo'}}
                                                            </span>

                                                            <b-link
                                                                v-if="(children.image || children.image_path)"
                                                                href="#"
                                                                class="file-selector-label d-flex align-items-center justify-content-end font-italic"
                                                                @click.stop.prevent="onResetAttachment(children, 'image')"
                                                            >
                                                                Remove
                                                                <button
                                                                    type="button"
                                                                    class="close close-btn"
                                                                    aria-label="Close"
                                                                >
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </b-link>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="pt-2 pb-2" v-if="children.applied">
                                                        <b-col
                                                            cols="12"
                                                            class="d-flex align-items-center"
                                                        >
                                                            <b-form-input
                                                                v-model="children.caption"
                                                                aria-label="Enter Caption Image"
                                                                placeholder="Enter Caption Image"
                                                                trim
                                                                type="text"
                                                            />
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </b-col>
                        </b-row>

                <!-- <b-container v-if="availableCsiIndexEntries.length !== 0 && attributes.csi_sub_divisions.length < getCsiSubDivisionCountOnPlan()"> -->
                <!-- <b-container v-if="availableCsiIndexEntries.length !== 0"> -->
                    <!-- <b-row -->
                        <!-- class="available-sub-divisions" -->
                    <!-- > -->
                        <!-- <b-col -->
                            <!-- v-for="(indexEntry, index) in availableCsiIndexEntries" -->
                            <!-- :key="indexEntry.id" -->
                            <!-- cols="6" -->
                            <!-- md="4" -->
                            <!-- class="available-sub-division" -->
                        <!-- > -->
                            <!-- <b-button -->
                                <!-- pill -->
                                <!-- size="sm" -->
                                <!-- variant="outline-secondary" -->
                                <!-- class="action" -->
                                <!-- @click="onAvailableCsiIndexEntryAdd(index)" -->
                            <!-- > -->
                                <!-- + -->
                            <!-- </b-button> -->

                            <!-- <div class="title"> -->
                                <!-- {{ indexEntry.code }} {{ indexEntry.title }} -->
                            <!-- </div> -->
                        <!-- </b-col> -->
                    <!-- </b-row> -->
                <!-- </b-container> -->

                <!-- <b-container -->
                    <!-- v-if="attributes.csi_sub_divisions.length !== 0" -->
                    <!-- class="mb-3" -->
                <!-- > -->
                        <!-- v-if="sub_division_index < getCsiSubDivisionCountOnPlan()" -->
                    <!-- <b-row -->
                        <!-- v-for="(sub_division, sub_division_index) in attributes.csi_sub_divisions" -->
                        <!-- v-if="sub_division_index < getCsiSubDivisionCountOnPlan()" -->
                        <!-- :key="sub_division.id" -->
                        <!-- class="sub-division" -->
                    <!-- > -->
                        <!-- <b-col class="ellipsis"> -->
                            <!-- {{sub_division_index}} => {{ sub_division.csi_index_entry.code }} {{ sub_division.csi_index_entry.title }} -->
                        <!-- </b-col> -->

                        <!-- <b-col cols="auto"> -->
                            <!-- <b-button -->
                                <!-- pill -->
                                <!-- size="sm" -->
                                <!-- variant="outline-danger" -->
                                <!-- @click="onSubDivisionRemove(sub_division)" -->
                            <!-- > -->
                                <!-- Remove -->
                            <!-- </b-button> -->
                        <!-- </b-col> -->
                    <!-- </b-row> -->
                <!-- </b-container> -->

                <!-- <b-row v-else class="sub-division mb-3"> -->
                    <!-- <b-col class="text-center text-muted"> -->
                        <!-- There are no sub-divisions. -->
                    <!-- </b-col> -->
                <!-- </b-row> -->

<!--                 <b-row v-if="attributes.csi_sub_divisions.length > getCsiSubDivisionCountOnPlan()" class="sub-division mb-3">
                    <b-col class="text-center text-muted">
                        There are <b>{{attributes.csi_sub_divisions.length}}</b> CSI sub divisions but manufacturer have <b>{{this.originalAttributes.plan ? this.originalAttributes.plan : "no"}}</b> subscription.
                    </b-col>
                </b-row>

                <b-row v-if="getCsiSubDivisionCountOnPlan() < 0" class="sub-division mb-3">
                    <b-col class="text-center text-muted">
                        Manufacturer must have subscription to add CSI sub divisions.<br>
                        1. <b>Basic</b> subscription can add <b>8</b> CSI sub divisions.<br>
                        2. <b>Standard</b> subscription can add <b>12</b> CSI sub divisions.<br>
                        3. <b>Premium</b> subscription can add <b>16</b> CSI sub divisions.
                    </b-col>
                </b-row> -->
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-group
                    id="manufacturer_images_fieldset"
                    label="Images"
                    label-for="manufacturer_images"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-file
                        id="manufacturer_images"
                        ref="images-file-input"
                        placeholder="Choose a file or drop them here..."
                        drop-placeholder="Drop files here..."
                        accept="image/jpeg, image/png, image/gif"
                        multiple
                        @input="onImagesUpload"
                    />
                </b-form-group>

                <b-row>
                    <b-col
                        v-for="(image, index) in attributes.images"
                        :key="image.id || image.internalId"
                        cols="12"
                        md="6"
                        class="mb-2"
                        v-if="image['_destroy'] == false || image['_destroy'] === undefined"
                    >
                        <b-card no-body class="overflow-hidden">
                            <b-row no-gutters>
                                <b-col md="4">
                                    <b-card-img
                                        :src="image.image_path"
                                        alt="Image"
                                        class="rounded-0"
                                    />
                                </b-col>
                                <b-col md="8">
                                    <b-card-body>
                                        <b-form-group
                                            :id="`manufacturer_image_caption_${index}_fieldset`"
                                            label="Caption"
                                            :label-for="`manufacturer_image_caption_${index}`"
                                        >
                                            <b-form-input
                                                :id="`manufacturer_image_caption_${index}`"
                                                v-model="image.caption"
                                                placeholder="Enter caption..."
                                                type="text"
                                                trim
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            :id="`manufacturer_image_kind_${index}_fieldset`"
                                        >
                                            <b-form-select
                                                :id="`manufacturer_image_kind_${index}`"
                                                v-model="image.kind"
                                                :options="kindOptions"
                                            />
                                        </b-form-group>

                                        <b-form-group
                                            v-if="image.kind == 'cover'"
                                            :id="`manufacturer_image_csi_index_entry_${index}_fieldset`"
                                            label="CSI Index Entry"
                                            :label-for="`manufacturer_image_csi_index_entry_${index}`"
                                        >
                                            <b-form-select
                                                :id="`manufacturer_image_csi_index_entry_${index}`"
                                                v-model="image.csi_index_entry_id"
                                                :options="imageCsiIndexEntries"
                                                :disabled="image.kind === 'logo'"
                                            >
                                                <template #first>
                                                    <b-form-select-option :value="null">
                                                        Enter CSI Index Entry
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>

                                        <b-button
                                            pill
                                            size="sm"
                                            variant="outline-secondary"
                                            @click="onImageUp(index)"
                                        >
                                            Up
                                        </b-button>

                                        <b-button
                                            pill
                                            size="sm"
                                            variant="outline-secondary"
                                            @click="onImageDown(index)"
                                        >
                                            Down
                                        </b-button>

                                        <b-button
                                            pill
                                            size="sm"
                                            variant="outline-danger"
                                            @click="onImageRemove(index)"
                                        >
                                            Remove
                                        </b-button>
                                    </b-card-body>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <b-form-group
                    id="manufacturer_keyword_list_fieldset"
                    label="Keywords"
                    label-for="manufacturer_keyword_list"
                    :invalid-feedback="keywordListError"
                    :state="keywordListState"
                    label-cols-md="3"
                    label-align-md="left"
                >
                    <b-form-tags
                        id="manufacturer_keyword_list"
                        v-model="attributes.keyword_list"
                        :state="keywordListState"
                        remove-on-delete
                        separator=","
                        :limit="100"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-button
                    size="sm"
                    variant="primary"
                    class="px-5"
                    @click="onSave"
                >
                    Save
                </b-button>
            </b-col>
        </b-row>
        <b-modal
            id="ask-deny-reason"
            title="Write reason"
            size="md"
        >
            <b-form-textarea
                        v-model.trim="originalAttributes.comment"
                        type="text"
                        placeholder="Write your comment..."
                        rows="6"
                        max-rows="6"
                    />

            <template #modal-footer>
                <b-button
                    variant="primary"
                    @click="onClickDeny()"
                >
                    Submit
                </b-button>

                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('ask-deny-reason')"
                >
                    Cancel
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="send-admin-email"
            title="Email Admin"
            size="lg"
        >
            <h6>Message</h6>
            <b-form-textarea
                v-model="adminEmailMessage"
                required
                placeholder="Write your message"
                class="message-textarea"
                rows="6"
                style="width: 100%;"
            />

            <template #modal-footer>
                <b-button
                    variant="primary"
                    @click="onClickSendAdminEmail"
                >
                    Send
                </b-button>

                <b-button
                    variant="secondary"
                    @click="$bvModal.hide('send-admin-email')"
                >
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment-timezone"
import { mapGetters } from "vuex"
import { DirectUpload } from "@rails/activestorage"
import countryRegionData from "country-region-data/data"
import { DESCRIPTIONS } from "enums/descriptions"
import applications from "enums/applications"
import markets from "enums/markets"
import quickLinks from "enums/quick-links"
import employees from "enums/employees"
import { firmProfileState } from "enums/profile-states"
import { blobImage } from "api/blob-image"
import { csiSubDivisions, csiDivision, divisionNestedSubDivisions } from "api/csi-entries"
import { getManufacturer, saveManufacturer, deleteManufacturer } from "api/admin/manufacturers"
import { deepCopy } from "mixins/deep-copy"
import Multiselect from "vue-multiselect"
import { getCsiIndexEntries, getCsiIndexEntry } from "api/admin/csi-index-entries"

export default {
    components: {
        Multiselect
    },
    data () {
        const baseURL = document.querySelector("meta[name=\"base-url\"]").content
        const directUploadPath = document.querySelector("meta[name=\"direct-upload-path\"]").content
        const directUploadUrl = directUploadPath.startsWith("/")
            ? baseURL + directUploadPath.slice(1)
            : baseURL + directUploadPath

        const descriptions = Object.entries(DESCRIPTIONS).map(e => {
            return { value: e[0], text: e[1] }
        })
        const currentYear = moment().year()
        const attributeDefinitions = [
            { name: "profile_state", simple: true, default: "submitted" },
            { name: "profile_type", simple: true, default: "4-basic" },
            { name: "status", simple: true, default: 1 },
            { name: "div", simple: true, default: null },
            { name: "uid", simple: true, default: null },
            { name: "company_name", simple: true, default: "" },
            { name: "country", simple: true, default: null },
            { name: "state", simple: true, default: null },
            { name: "zip", simple: true, default: null },
            { name: "city", simple: true, default: null },
            { name: "address", simple: true, default: null },
            { name: "email", simple: true, default: null },
            // { name: "catalogue_email", simple: true, default: null },
            // { name: "sample_email", simple: true, default: null },
            // { name: "quotes_email", simple: true, default: null },
            { name: "phone", simple: true, default: null },
            { name: "site", simple: true, default: null },
            { name: "url_on_profile", simple: true, default: null },
            { name: "statement", simple: true, default: "" },
            { name: "principals", simple: true, default: "" },
            { name: "applications", simple: true, default: "" },
            { name: "linkedin_link", simple: true, default: null },
            { name: "twitter_link", simple: true, default: null },
            { name: "facebook_link", simple: true, default: null },
            { name: "instagram_link", simple: true, default: null },
            { name: "youtube_link", simple: true, default: null },
            { name: "pinterest_link", simple: true, default: null },
            { name: "request_info_link", simple: true, default: null },
            // { name: "rep_link", simple: true, default: null },
            { name: "request_samples_link", simple: true, default: null },
            { name: "request_quote_link", simple: true, default: null },
            { name: "products_link", simple: true, default: null },
            { name: "catalogs_link", simple: true, default: null },
            { name: "videos_link", simple: true, default: null },
            { name: "specifications_link", simple: true, default: null },
            { name: "leed_info_link", simple: true, default: null },
            { name: "cad_link", simple: true, default: null },
            { name: "bim_link", simple: true, default: null },
            { name: "revit_link", simple: true, default: null },
            { name: "ceu_link", simple: true, default: null },
            { name: "green_statement", simple: true, default: "" },
            { name: "products", simple: true, default: "" },
            { name: "latest_products", simple: true, default: "" },
            { name: "showrooms_url", simple: true, default: "" },
            { name: "primary_csi_division", simple: true, default: "" },
            { name: "showrooms_note", simple: true, default: "" },
            // { name: "employees", simple: true, default: "1-10" },
            { name: "employees", simple: true, default: "" },
            { name: "green_products", simple: false, default: false },
            { name: "year_founded", simple: true, default: currentYear },
            { name: "market", simple: true, default: "international" },
            { name: "quick_links", simple: false, default: this.allQuickLinks([]) },
            { name: "green_statement_links", simple: false, default: [{title: "", url: ""}] },
            { name: "product_links", simple: false, default: [{title: "", url: ""}] },
            { name: "latest_products_links", simple: false, default: [{title: "", url: ""}] },
            { name: "images", simple: false, default: [] },
            // { name: "csi_index_entries", simple: false, default: [] },
            { name: "csi_sub_divisions", simple: false, default: [] },
            { name: "keyword_list", simple: true, default: [] },
            { name: "approved_at", simple: true, default: new Date },
            { name: "comment", simple: true, default: "" },
        ]

        const attributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = info.default
            return hash
        }, {})
        const originalAttributes = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = info.default
            return hash
        }, {})

        const attributeErrors = attributeDefinitions.reduce((hash, info) => {
            hash[info.name] = []
            return hash
        }, {})

        return {
            descriptions,
            employees,
            markets,
            applications,
            quickLinks,
            attributeDefinitions,
            attributes,
            attributeErrors,
            directUploadUrl,
            internalImageId: 0,
            csiIndexQuery: "",
            csi_divisions: [],
            isLoadingPrimaryTypeCodeCsiIndexEntries: false,
            availableCsiIndexEntries: [],
            csiIndexEntryOptions: [],
            csiIndexEntryNestedOptions: [],
            profileCsiSubDivisions: [],
            isLoadingCsiIndexEntries: false,
            isReasonSubmitted: false,
            originalAttributes,
            kindOptions: [
                { value: "logo", text: "Logo" },
                { value: "cover", text: "Cover" },
                { value: "carousel", text: "Featured Photo" }
            ],
            profileStateOptions: [
                {value: 'application', text: "Application"},
                {value: 'pre-approved', text: "Pre-approved"},
                {value: 'submitted', text: "Submitted"},
                {value: 'payment-pending', text: "Payment-pending"},
                {value: 'published', text: "Published"},
                {value: 'denied', text: "Denied"}
                // { value: "first-saved", text: "Basic Submission Saved" },
                // { value: "first-submitted", text: "Basic Submission Submitted" },
                // { value: "first-approved", text: "Basic Submission Approved" },
                // { value: "payment-pending", text: "Payment Pending" },
                // { value: "second-saved", text: "Full Submission Saved" },
                // { value: "second-submitted", text: "Full Submission Submitted" },
                // { value: "second-approved", text: "Full Submission Approved" },
                // { value: "published", text: "Published" },
                // { value: "suspended", text: "Suspended" }
            ],
            profileTypeOptions: [
                { value: "5-basic-unbuilt", text: "5-basic-unbuilt" },
                { value: "4-basic", text: "4-basic" },
                { value: "3-editorial", text: "3-editorial" },
                { value: "2-standard", text: "2-standard" },
                { value: "1-premium", text: "1-premium" },
            ],
            statusOptions: [
                { value: "5", text: "5" },
                { value: "4", text: "4" },
                { value: "3", text: "3" },
                { value: "2", text: "2" },
                { value: "1", text: "1" },
            ],
            adminEmailMessage: '',
        }
    },

    computed: {
        ...mapGetters("account", ["adminToken"]),

        countries () {
            return [
                {value: "US", text: "United States"},
                {value: "CA", text: "Canada"}
                ]
            // return countryRegionData.map(country => {
            //     return {
            //         value: country.countryShortCode,
            //         text: country.countryName
            //     }
            // })
        },

        states () {
            const country = this.attributes.country
            let prohibited_states = ["American Samoa","Micronesia","Guam","Marshall Islands","Palau","Puerto Rico","Virgin Islands","Armed Forces Americas","Armed Forces Europe","Armed Forces Pacific","Armed Forces Europe, Canada, Africa and Middle East"]
            if (country) {
                const entry = countryRegionData.find(
                    entry => entry.countryShortCode === country)
                if (entry) {
                    return entry.regions.filter(f => !prohibited_states.includes(f.name) ).map(region => {
                        return { value: region.shortCode, text: region.name }
                    })
                }
            }
            return []
        },

        imageCsiIndexEntries () {
            return this.attributes.csi_sub_divisions.map(entry => {
                const csi_index_entry = entry.csi_index_entry
                return { value: csi_index_entry.id, text: `${ csi_index_entry.code } ${ csi_index_entry.title }` }
            })
        },

        logoState () { return null },
        logoError () { return "" },
        divState () { return null },
        divError () { return "" },
        uidState () { return null },
        uidError () { return "" },
        profileStateState () { return null },
        profileTypeState () { return null },
        profileStateError () { return "" },
        profileTypeError () { return "" },
        StatusError () { return "" },
        StatusState () { return null },
        companyNameState () { return null },
        companyNameError () { return "" },
        FindARepState () { return null },
        FindARepError () { return "" },
        approvedAtState () { return null },
        approvedAtError () { return "" },
        countryState () { return null },
        countryError () { return "" },
        stateState () { return null },
        stateError () { return "" },
        zipState () { return null },
        zipError () { return "" },
        cityState () { return null },
        cityError () { return "" },
        addressState () { return null },
        addressError () { return "" },
        emailState () { return null },
        emailError () { return "" },
        phoneState () { return null },
        phoneError () { return "" },
        siteState () { return null },
        siteError () { return "" },
        urlOnProfileState () { return null },
        urlOnProfileError () { return "" },
        statementState () { return null },
        statementError () { return "" },
        principalsState () { return null },
        principalsError () { return "" },
        employeesState () { return null },
        employeesError () { return "" },
        yearFoundedState () { return null },
        yearFoundedError () { return "" },
        marketState () { return null },
        marketError () { return "" },
        applicationsState () { return null },
        applicationsError () { return "" },
        quickLinksState () { return null },
        quickLinksError () { return "" },
        greenStatementState () { return null },
        greenStatementError () { return "" },
        greenStatementLinksState () { return null },
        greenStatementLinksError () { return "" },
        productsState () { return null },
        productsError () { return "" },
        productLinksState () { return null },
        productLinksError () { return "" },
        latestProductsState () { return null },
        latestProductsError () { return "" },
        latestProductsLinksState () { return null },
        latestProductsLinksError () { return "" },
        showroomsUrlState () { return null },
        showroomsUrlError () { return "" },
        primaryCsiDivisionState () { return null },
        primaryCsiDivisionError () { return "" },
        showroomsNoteState () { return null },
        showroomsNoteError () { return "" },
        imagesState () { return null },
        imagesError () { return "" },
        csiIndexEntriesState () { return null },
        csiIndexEntriesError () { return "" },
        keywordListState () { return null },
        keywordListError () { return "" },
    },

    watch: {
        ["attributes.country"] () {
            this.attributes.state = null
        },
    },

    mounted () {
        this.onLoad()
    },

    created () {
        window.addEventListener('scroll', this.handleScroll);
    },

    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll (event) {
          
        },
        limitTextCsiIndexEntries (count) {
            return `and ${ count } other entries`
        },

        asyncFindCsiIndexEntries (query) {
            this.isLoadingCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { type: "primary_type", query })
                .then(result => {
                    const options = result.map(entry => {
                        return { value: entry, text: `${entry.code} ${entry.title}` }
                    })
                    this.csiIndexEntryOptions.splice(0, this.csiIndexEntryOptions.length, ...options)
                    // if(typeof this.attributes.primary_csi_division == 'number'){
                    //     let selectedPrimaryCsiDivision = result.filter( fil => fil.id == this.attributes.primary_csi_division)[0];
                    //     this.attributes.primary_csi_division = { value: selectedPrimaryCsiDivision, text: `${selectedPrimaryCsiDivision.code} ${selectedPrimaryCsiDivision.title}` };
                    // }
                    this.isLoadingCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingCsiIndexEntries = false
                })
        },

        clearCsiDivision () {
            this.attributes.div = null;
        },
        customCsiDivisionLabel (code) {
            const csi_division = this.csi_divisions.find(i => i.code === code)
            return csi_division ? `${csi_division.code} ${csi_division.title}` : code
        },
        asyncFindPrimaryTypeCodeCsiIndexEntries (query) {
            this.isLoadingPrimaryTypeCodeCsiIndexEntries = true
            getCsiIndexEntries(this.adminToken, { type: "primary_type", query })
                .then(result => {
                    const divisions = result.map(entry => {
                        // return entry.code
                        return { value: entry.code, text: `${entry.code} ${entry.title}` }
                    })
                    this.csi_divisions.splice(0, this.csi_divisions.length, ...divisions)
                    if(typeof this.attributes.div == 'string'){
                        let selectedDiv = this.csi_divisions.filter( fil => fil.value == this.attributes.div)[0];
                        this.attributes.div = {value: selectedDiv?.value, text: selectedDiv?.text};
                    }
                    this.isLoadingPrimaryTypeCodeCsiIndexEntries = false
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                    this.isLoadingPrimaryTypeCodeCsiIndexEntries = false
                })
        },

        onClickDelete () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#dc3545;">DELETE</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
                .then(isConmanufacturered => {
                    if (isConmanufacturered) {
                        deleteManufacturer(this.adminToken, this.originalAttributes)
                            .then(() => {
                                // this.$bvToast.toast("Manufacturer deleted.", {
                                //     title: "Success",
                                //     variant: "success",
                                //     autoHideDelay: 5000
                                // })
                                this.$nextTick(() => {
                                    this.$router.push({ name: "admin-manufacturers" })
                                })
                            })
                            .catch(error => {
                                this.$bvToast.toast(error.message, {
                                    title: "Unable to delete",
                                    variant: "danger",
                                    autoHideDelay: 5000
                                })
                            })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unknown error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        updateProfileState (profileState) {
            const record = {
                id: this.originalAttributes.id,
                profile_state: profileState,
                comment: this.originalAttributes.comment,
            }
            saveManufacturer(this.adminToken, record)
                .then(result => {
                    Object.assign(this.originalAttributes, result)
                    this.$bvToast.toast("Succeeded!", {
                        title: "Save status",
                        variant: "success",
                        autoHideDelay: 5000
                    })
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickApprove () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#28a745;">APPROVE</span> this application?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "success",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    let newProfileState = null
                    switch (this.originalAttributes.profile_state) {
                    case "application":
                        newProfileState = "pre-approved"
                        break
                    case "submitted":
                        newProfileState = "published"
                        break
                    default:
                        break
                    }
                    if (newProfileState) {
                        this.updateProfileState(newProfileState)
                    }
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickDeny () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#dc3545;">DENY</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                okTitle: "Yes",
                cancelTitle: "Cancel",
                hideHeader: true,
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    let newProfileState = null
                    switch (this.originalAttributes.profile_state) {
                    case "pre-approved":
                        newProfileState = "application"
                        break
                    case "submitted":
                        newProfileState = "pre-approved"
                        break
                    default:
                        break
                    }
                    if (newProfileState) {
                        this.updateProfileState(newProfileState);
                        this.isReasonSubmitted = true;
                        this.$bvModal.hide('ask-deny-reason');
                    }
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickSuspend () {
            const h = this.$createElement
            const message = h('div', { domProps: { innerHTML: '<strong style="-webkit-font-smoothing:antialiased;">Are you sure you want to <span style="color:#ffc107;">SUSPEND</span> this profile?</strong>' } })
            this.$bvModal.msgBoxConfirm(message, {
                title: "Please Confirm",
                size: "md",
                okVariant: "danger",
                hideHeader: true,
                okTitle: "Yes",
                cancelTitle: "Cancel",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true
            })
            .then(isConfirmed => {
                if (isConfirmed) {
                    this.updateProfileState("suspended")
                }
            })
            .catch(error => {
                this.$bvToast.toast(error.message, {
                    title: "Unknown error",
                    variant: "danger",
                    autoHideDelay: 5000
                })
            })
        },

        onClickPublish () {
            this.updateProfileState("published")
        },

        onUpload (record, attribute, fileOrFiles) {
            if (Array.isArray(fileOrFiles)) {
                fileOrFiles.forEach(file => this.uploadAttachment(record, attribute, file))
            } else {
                this.uploadAttachment(record, attribute, fileOrFiles)
            }
        },

        onResetAttachment (record, attribute) {
            record[attribute] = null
            record[`${ attribute }_path`] = null
            record[`${ attribute }_picker`] = null
            record[`${ attribute }_filename`] = null
            this.$forceUpdate();
        },

        uploadAttachment (record, attribute, file) {
            if (file === null) return
            const upload = new DirectUpload(file, this.directUploadUrl)
            upload.create((error, blob) => {
                if (error) {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                } else {
                    blobImage(blob.signed_id)
                        .then(path => {
                            record[attribute] = blob.signed_id
                            record[`${ attribute }_path`] = path
                            record[`${ attribute }_filename`] = blob.filename
                            record[`${ attribute }_picker`] = null
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to upload",
                                variant: "danger",
                                autoHideDelay: 5000
                            })                    
                            this.onResetAttachment(record, attribute)
                        })
                }
            })
        },

        onLogoUpload (fileOrFiles) {
            if (Array.isArray(fileOrFiles)) {
                fileOrFiles.forEach(file => this.uploadLogo(file))
            } else {
                this.uploadLogo(fileOrFiles)
            }
        },

        onImagesUpload (fileOrFiles) {
            if (Array.isArray(fileOrFiles)) {
                fileOrFiles.forEach(file => this.uploadImage(file))
            } else {
                this.uploadImage(fileOrFiles)
            }
        },

        onResetLogo () {
            this.$refs["logo-file-input"].reset()
        },

        onResetImages () {
            this.$refs["images-file-input"].reset()
        },

        uploadLogo (file) {
            if (file === null) return
            const upload = new DirectUpload(file, this.directUploadUrl)
            upload.create((error, blob) => {
                if (error) {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                } else {
                    blobImage(blob.signed_id)
                        .then(path => {
                            this.attributes.logo = blob.signed_id
                            this.attributes.logo_path = path
                            this.onResetLogo()
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to upload",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                            this.onResetLogo()
                        })
                }
            })
        },

        uploadImage (file) {
            if (file === null) return
            const upload = new DirectUpload(file, this.directUploadUrl)
            upload.create((error, blob) => {
                if (error) {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to upload",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                } else {
                    blobImage(blob.signed_id)
                        .then(path => {
                            this.attributes.images.push(
                                {
                                    id: null,
                                    internalId: `uploaded_image_${this.internalImageId++}`,
                                    caption: "",
                                    kind: "carousel",
                                    image: blob.signed_id,
                                    image_path: path,
                                    csi_index_entry_id: null,
                                }
                            )
                            this.onResetImages()
                        })
                        .catch(error => {
                            this.$bvToast.toast(error.message, {
                                title: "Unable to upload",
                                variant: "danger",
                                autoHideDelay: 5000
                            })
                            this.onResetImages()
                        })
                }
            })
        },

        allQuickLinks (links) {
            return Object.entries(quickLinks).map(e => {
                const existing = links.find(l => l.type === e[0])
                return existing || { type: e[0], url: "" }
            })
        },

        onGreenStatementLinkUp (index) {
            if (index > 0) {
                const item1 = this.attributes.green_statement_links[index - 1]
                const item2 = this.attributes.green_statement_links[index]
                this.attributes.green_statement_links.splice(index - 1, 1, item2)
                this.attributes.green_statement_links.splice(index, 1, item1)
            }
        },

        onGreenStatementLinkDown (index) {
            if (index < this.attributes.green_statement_links.length - 1) {
                const item1 = this.attributes.green_statement_links[index]
                const item2 = this.attributes.green_statement_links[index + 1]
                this.attributes.green_statement_links.splice(index, 1, item2)
                this.attributes.green_statement_links.splice(index + 1, 1, item1)
            }
        },

        onGreenStatementLinkAdd (index) {
            this.attributes.green_statement_links.splice(index + 1, 0, { title: "", url: "" })
        },

        onGreenStatementLinkRemove (index) {
            this.attributes.green_statement_links.splice(index, 1)
            if (this.attributes.green_statement_links.length === 0) {
                this.attributes.green_statement_links.splice(0, 0, { title: "", url: "" })
            }
        },

        onProductLinkUp (index) {
            if (index > 0) {
                const item1 = this.attributes.product_links[index - 1]
                const item2 = this.attributes.product_links[index]
                this.attributes.product_links.splice(index - 1, 1, item2)
                this.attributes.product_links.splice(index, 1, item1)
            }
        },

        onProductLinkDown (index) {
            if (index < this.attributes.product_links.length - 1) {
                const item1 = this.attributes.product_links[index]
                const item2 = this.attributes.product_links[index + 1]
                this.attributes.product_links.splice(index, 1, item2)
                this.attributes.product_links.splice(index + 1, 1, item1)
            }
        },

        onProductLinkAdd (index) {
            this.attributes.product_links.splice(index + 1, 0, { title: "", url: "" })
        },

        onProductLinkRemove (index) {
            this.attributes.product_links.splice(index, 1)
            if (this.attributes.product_links.length === 0) {
                this.attributes.product_links.splice(0, 0, { title: "", url: "" })
            }
        },

        onLatestProductLinkUp (index) {
            if (index > 0) {
                const item1 = this.attributes.latest_products_links[index - 1]
                const item2 = this.attributes.latest_products_links[index]
                this.attributes.latest_products_links.splice(index - 1, 1, item2)
                this.attributes.latest_products_links.splice(index, 1, item1)
            }
        },

        onLatestProductLinkDown (index) {
            if (index < this.attributes.latest_products_links.length - 1) {
                const item1 = this.attributes.latest_products_links[index]
                const item2 = this.attributes.latest_products_links[index + 1]
                this.attributes.latest_products_links.splice(index, 1, item2)
                this.attributes.latest_products_links.splice(index + 1, 1, item1)
            }
        },

        onLatestProductLinkAdd (index) {
            this.attributes.latest_products_links.splice(index + 1, 0, { title: "", url: "" })
        },

        onLatestProductLinkRemove (index) {
            this.attributes.latest_products_links.splice(index, 1)
            if (this.attributes.latest_products_links.length === 0) {
                this.attributes.latest_products_links.splice(0, 0, { title: "", url: "" })
            }
        },

        onImageUp (index) {
            if (index > 0) {
                const item1 = this.attributes.images[index - 1]
                const item2 = this.attributes.images[index]
                this.attributes.images.splice(index - 1, 1, item2)
                this.attributes.images.splice(index, 1, item1)
            }
        },

        onImageDown (index) {
            if (index < this.attributes.images.length - 1) {
                const item1 = this.attributes.images[index]
                const item2 = this.attributes.images[index + 1]
                this.attributes.images.splice(index, 1, item2)
                this.attributes.images.splice(index + 1, 1, item1)
            }
        },

        onImageRemove (index) {
            // this.attributes.images.splice(index, 1)
            this.attributes.images[index]['_destroy'] = true
            this.$forceUpdate();
        },

        onCsiIndexQueryChanged (query) {
            if (query.length >= 2) {
                // csiSubDivisions({ query })
                csiDivision({ code: query })
                    .then(entries => {
                        this.availableCsiIndexEntries = entries.filter(e => {
                            const existing = this.attributes.csi_sub_divisions
                                .find(s => s.csi_index_entry.id === e.id)
                            return !existing
                        })
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Server error",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            } else {
                if (this.availableCsiIndexEntries.length !== 0) {
                    this.availableCsiIndexEntries.splice(0, this.availableCsiIndexEntries.length)
                }
            }
            // csiProductTypes
        },

        asyncSingleDivisionNestedSubDivision ( division = "issue.value.code" ) {
             divisionNestedSubDivisions({ division: division, nested_children: true })
                .then(result => {
                    result.children = result.children.map(ch => {
                        return {
                            id: null,
                            csiIndexEntry: ch,
                            code: ch.code,
                            title: ch.title,
                            applied: false,
                            disabled: false,
                            imageId: ch.image?.id,
                            image: ch.image,
                            image_path: ch.image_path,
                            image_picker: ch.image_picker,
                            image_filename: ch.image_filename,
                            caption: ch.caption,
                        }
                    })
                    this.csiIndexEntryNestedOptions.push(result);
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Error",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                    console.log("Error", error)
                })
        },

        onSelectCsiIndexEntry (item) {
            this.csiIndexEntryOptions.find(f => f.text === item.text)['$isDisabled'] = true
            if(!this.csiIndexEntryNestedOptions.filter(f => f.parent === `${item.value.code} ${item.value.title}`).length){
                this.asyncSingleDivisionNestedSubDivision(item.value.code);
            }
            const csiIndexEntry = item.value
            const existingCsiIndexEntryIndex = this.profileCsiSubDivisions.findIndex(ps => ps.csiIndexEntry.id === csiIndexEntry.id);
            // if(this.profileCsiSubDivisions.length === this.getCsiSubDivisionCountOnPlan()){
            //     this.currentCsiIndexEntry = null
            //     return
            // }
            if (existingCsiIndexEntryIndex === -1) {
                let renderedList = this.profileCsiSubDivisionsObject;
                // this.profileCsiSubDivisionsObject = {};
                if(!!csiIndexEntry.root_parent){
                    let root_parent = csiIndexEntry.root_parent
                    if(!renderedList[`${root_parent.code}`]){
                        renderedList[`${root_parent.code}`] = []
                    }
                    renderedList[`${root_parent.code}`].push(csiIndexEntry.code);
                }
                console.log('renderedList',renderedList);
                this.profileCsiSubDivisionsObject = renderedList;
                this.profileCsiSubDivisions.push({
                    id: null,
                    csiIndexEntry,
                    applied: true,
                    disabled: false,
                    imageId: undefined,
                    image: undefined,
                    image_path: undefined,
                    image_picker: undefined,
                    image_filename: undefined,
                    caption: undefined
                })
                // this.state.profileCsiSubDivisions.push(null)
                // this.errors.profileCsiSubDivisions.push([])
                this.currentCsiIndexEntry = null
            } else {
                this.currentCsiIndexEntry = null
            }
        },

        checkMeNextOptions (val) {
            // if(this.csiIndexEntryNestedOptions.reduce((partialSum, a) => partialSum + a.children.filter(f => f.applied).length, 0) === this.getCsiSubDivisionCountOnPlan()){
            //     this.csiIndexEntryOptions.map(m => m['$isDisabled'] = true)
            //     this.csiIndexEntryNestedOptions.map((m,i) => {
            //         m.children.map((mc,mci) => {
            //             if (!mc.applied) {
            //                 mc.disabled = true
            //             }
            //         });
            //     });
            // }else{
                this.csiIndexEntryOptions.filter(mc => !this.csiIndexEntryNestedOptions.find(m => m.parent === mc.text)).map(m => {
                    m['$isDisabled'] = false
                    delete m['$isDisabled']
                })
                this.csiIndexEntryNestedOptions.map(m => {
                    m.children.map(mc => {
                        if(!mc.applied){
                            mc.disabled = false
                        }
                    });
                });
            // }
            this.csiIndexEntryNestedOptions.map(csim => {
                csim.children.map(m => {
                    if(m.applied){
                       if(!this.attributes.csi_sub_divisions.find(f => f.csi_index_entry.id === m.csiIndexEntry.id )){
                            this.attributes.csi_sub_divisions.push({
                                id: null,
                                csi_index_entry: m.csiIndexEntry
                            })
                       }
                    }
                })
            });

            this.$forceUpdate();
        },

        onRemoveParentCsiEntry (parent_object) {
            delete this.csiIndexEntryOptions.find(f => f.text === `${parent_object.code} ${parent_object.title}`)['$isDisabled']
            const csiNestedIndex = this.csiIndexEntryNestedOptions.findIndex(f => f.parent === `${parent_object.code} ${parent_object.title}`)
            this.csiIndexEntryNestedOptions[csiNestedIndex].children.map(m => {
                this.profileCsiSubDivisions.splice(this.profileCsiSubDivisions.findIndex(f => f.csiIndexEntry.code === m.code),1)
                delete this.profileCsiSubDivisions[this.profileCsiSubDivisions.findIndex(f => f.csiIndexEntry.code === m.code)];
                let image = this.attributes.images.find(f=> f.csi_index_entry_id === m.csiIndexEntry.id )
                if(!!image){
                    console.log(image)
                    image['_destroy'] = true
                }
            });
            // console.log('this.attributes.images =========> ', this.attributes.images);
            this.profileCsiSubDivisions.splice(this.profileCsiSubDivisions.findIndex(f => f.csiIndexEntry.code === parent_object.code),1);
            delete this.profileCsiSubDivisions[this.profileCsiSubDivisions.findIndex(f => f.csiIndexEntry.code === parent_object.code)];
            delete this.csiIndexEntryNestedOptions[csiNestedIndex];
            this.csiIndexEntryNestedOptions.splice(csiNestedIndex,1);
            this.checkMeNextOptions(parent_object);
        },

        onAvailableCsiIndexEntryAdd (index) {
            const entry = this.availableCsiIndexEntries[index]
            this.attributes.csi_sub_divisions.push({
                id: undefined,
                csi_index_entry: entry,
            })
            this.availableCsiIndexEntries.splice(index, 1)
        },

        onSubDivisionRemove (subDivision) {
            const index = this.attributes.csi_sub_divisions.indexOf(subDivision)
            if (index !== -1) {
                this.attributes.csi_sub_divisions.splice(index, 1)
            }
        },

        onLoad () {
            const id = this.$route.params.id
            if (id) {
                getManufacturer(this.adminToken, { id })
                    .then(record => {
                        console.log("*** Loaded")
                        console.dir(record)
                        if (record.green_statement_links.length === 0) {
                            record.green_statement_links.splice(0, 0, { title: "", url: "" })
                        }
                        if (record.product_links.length === 0) {
                            record.product_links.splice(0, 0, { title: "", url: "" })
                        }
                        if (record.latest_products_links.length === 0) {
                            record.latest_products_links.splice(0, 0, { title: "", url: "" })
                        }
                        if(record.div && record.div.length>0){
                            this.asyncFindPrimaryTypeCodeCsiIndexEntries(record.div);
                        }
                        if(!!record.comment.length){
                            this.isReasonSubmitted = true
                        }
                            this.isLoadingCsiIndexEntries = true
                            getCsiIndexEntries(this.adminToken, { type: "primary_type", query: '' })
                                .then(result => {
                                    const options = result.map(entry => {
                                        return { value: entry, text: `${entry.code} ${entry.title}` }
                                    })
                                    this.csiIndexEntryOptions.splice(0, this.csiIndexEntryOptions.length, ...options)
                                    if(record.primary_csi_division && record.primary_csi_division > 0){
                                        this.attributes.primary_csi_division = this.csiIndexEntryOptions.find(x => x.value.id === record.primary_csi_division)
                                    }
                                    this.csiIndexEntryOptions.map(m => m['$isDisabled'] = true)
                                    // if(this.csiIndexEntryNestedOptions.reduce((partialSum, a) => partialSum + a.children.filter(f => f.applied).length, 0) === this.getCsiSubDivisionCountOnPlan()){
                                    //     this.csiIndexEntryNestedOptions.map(m => {
                                    //         m.children.map(mc => {
                                    //             if(!mc.applied){
                                    //                 mc.disabled = true
                                    //             }
                                    //         });
                                    //     });
                                    // }else{
                                        this.csiIndexEntryOptions.filter(mc => !this.csiIndexEntryNestedOptions.find(m => m.parent === mc.text)).map(m => {
                                            m['$isDisabled'] = false
                                            delete m['$isDisabled']
                                        })
                                        this.csiIndexEntryNestedOptions.map(m => {
                                            m.children.map(mc => {
                                                if(!mc.applied){
                                                    mc.disabled = false
                                                }
                                            });
                                        });
                                    // }
                                    this.isLoadingCsiIndexEntries = false
                                })
                                .catch(error => {
                                    this.$bvToast.toast(error.message, {
                                        title: "Error",
                                        variant: "danger",
                                        autoHideDelay: 5000
                                    })
                                    console.log("Error", error)
                                    this.isLoadingCsiIndexEntries = false
                                })
                                this.applyRecord(record)
                    })
                    .catch(error => {
                        this.$bvToast.toast(error.message, {
                            title: "Unable to load",
                            variant: "danger",
                            autoHideDelay: 5000
                        })
                    })
            }
        },

        planName () {
            if(this.originalAttributes.plan == "" || this.originalAttributes.plan == null){
                return "No Plan"
            }else{
                return `${this.originalAttributes.plan} plan` 
            }
        },

        getCsiSubDivisionCountOnPlan () {
            if(this.originalAttributes.status == 1 || this.originalAttributes.profile_type == '1-premium' || this.originalAttributes.plan == 'Premium' || this.originalAttributes.plan == 'premium'){
                return 16
            }else if(this.originalAttributes.status == 2 || this.originalAttributes.profile_type == '2-standard' || this.originalAttributes.plan == 'Standard' || this.originalAttributes.plan == 'standard'){
                return 12
            }else if(this.originalAttributes.status == 4 || this.originalAttributes.profile_type == '4-basic' || this.originalAttributes.plan == 'Basic' || this.originalAttributes.plan == 'basic'){
                return 8
            }else{
                return -1
            }
        },

        applyRecord (record) {
            this.originalAttributes = deepCopy(record)
            this.csiIndexEntryNestedOptions = this.originalAttributes.csi_sub_divisions.filter(f=> f.csi_index_entry.entry_type === 'primary')
            this.csiIndexEntryNestedOptions.forEach(fe => {
                fe.parent_object = fe.csi_index_entry
                fe.parent = `${fe.csi_index_entry.code} ${fe.csi_index_entry.title}`
                fe.children = fe.csi_index_entry.childrens.map(ch => {
                    let mcsd = this.originalAttributes.csi_sub_divisions.find(csd => csd.csi_index_entry.id === ch.id )
                    let image = this.originalAttributes.images.find(image => image.csi_index_entry && image.csi_index_entry.id === ch.id)
                    return {
                        id: mcsd ? mcsd.id : null,
                        csiIndexEntry: ch,
                        code: ch.code,
                        title: ch.title,
                        applied: !!mcsd,
                        disabled: false,
                        imageId: image?.id,
                        image: undefined,
                        image_path: image?.image_path,
                        image_picker: undefined,
                        image_filename: image?.image_filename,
                        caption: image?.caption
                    }
                })
            })
            // this.csiIndexEntryNestedOptions = this.csiIndexEntryNestedOptions;
            this.profileCsiSubDivisions = this.originalAttributes.csi_sub_divisions.map(csiSubDivision => {
                const image = this.originalAttributes.images.find(image => image.csi_index_entry &&
                    image.csi_index_entry.id === csiSubDivision.csi_index_entry.id)
                return {
                    id: csiSubDivision.id,
                    csiIndexEntry: csiSubDivision.csi_index_entry,
                    applied: true,
                    disabled: false,
                    imageId: image && image.id,
                    image: undefined,
                    image_path: image && image.image_path,
                    image_picker: undefined,
                    image_filename: image && image.image_filename,
                    caption: image && image.caption,
                }
            })
            this.csiIndexEntryOptions.map(m => m['$isDisabled'] = true)
            // if(this.csiIndexEntryNestedOptions.reduce((partialSum, a) => partialSum + a.children.filter(f => f.applied).length, 0) === this.getCsiSubDivisionCountOnPlan()){
            //     this.csiIndexEntryNestedOptions.map(m => {
            //         m.children.map(mc => {
            //             if(!mc.applied){
            //                 mc.disabled = true
            //             }
            //         });
            //     });
            // }else{
                this.csiIndexEntryOptions.filter(mc => !this.csiIndexEntryNestedOptions.find(m => m.parent === mc.text)).map(m => {
                    m['$isDisabled'] = false
                    delete m['$isDisabled']
                })
                this.csiIndexEntryNestedOptions.map(m => {
                    m.children.map(mc => {
                        if(!mc.applied){
                            mc.disabled = false
                        }
                    });
                });
            // }

            this.attributeDefinitions.forEach(definition => {
                const name = definition.name
                let value = deepCopy(record[name])
                this.attributes[name] = value
                this.attributeErrors[name].splice(0, this.attributeErrors[name].length)
            })
            this.csiIndexQuery = ""
            this.availableCsiIndexEntries.splice(0, this.availableCsiIndexEntries.length)

            this.$nextTick(() => {
                this.attributes["state"] = record.state
            })
        },

        onSave () {
            let record = {}
            if (this.originalAttributes) {
                record.id = this.originalAttributes.id
            }
            record.comment = this.originalAttributes.comment
            this.attributeDefinitions.forEach(definition => {
                if (definition.simple) {
                    const name = definition.name
                    if ((!this.originalAttributes && this.attributes[name] !== definition.default) ||
                        (this.originalAttributes && this.originalAttributes[name] !== this.attributes[name])) {
                        record[name] = this.attributes[name]
                    }
                }
            })
            if (!this.originalAttributes || this.originalAttributes.employees !== this.attributes.employees) {
                record.employees = this.attributes.employees
            }
            if (!this.originalAttributes || this.originalAttributes.year_founded !== this.attributes.year_founded) {
                record.year_founded = this.attributes.year_founded
            }
            if (!this.originalAttributes || this.originalAttributes.market !== this.attributes.market) {
                record.market = this.attributes.market
            }
            if (!this.originalAttributes || this.originalAttributes.green_products !== this.attributes.green_products) {
                record.green_products = this.attributes.green_products
            }
            
            this.csiIndexEntryNestedOptions.forEach((csi)=> {
                csi.children.forEach((csi_children)=> {
                    let single_csi_division_index = this.profileCsiSubDivisions.findIndex(f => f.csiIndexEntry.code === csi_children.code)
                    if(csi_children.applied){
                        if(single_csi_division_index !== -1){
                            this.profileCsiSubDivisions[single_csi_division_index] = csi_children
                        }else{
                            this.profileCsiSubDivisions.push(csi_children)
                        }
                    }else if(single_csi_division_index !== -1){
                       this.profileCsiSubDivisions.splice(single_csi_division_index - 1,single_csi_division_index);
                    }
                });
            });

            // Check if images were changed
            let position = 0
            record.images_attributes = []
            this.attributes.images.forEach(image => {
                record.images_attributes.push({
                    id: image.id || undefined,
                    kind: image.kind,
                    image: image.image || undefined,
                    caption: image.caption,
                    position: position++,//image.kind === "carousel" ? position++ : null,
                    csi_index_entry_id: image.csi_index_entry_id,
                    _destroy: image['_destroy']
                })
            })

            this.profileCsiSubDivisions.forEach(image => {
                if(!record.images_attributes.find(f => f.csi_index_entry_id === image.csiIndexEntry.id) && !!image.image){
                    record.images_attributes.push({
                        id: image.imageId || undefined,
                        kind: 'cover',
                        image: image.image || undefined,
                        caption: image.caption,
                        position: position++,
                        csi_index_entry_id: image.csiIndexEntry.id,
                    })
                }
            })

            // this.originalAttributes.images.forEach(image => {
                // const exists = this.profileCsiSubDivisions.find(csi => csi.imageId === image.id)
            //     if (!exists) {
            //         record.images_attributes.push({
            //             id: image.id,
            //             _destroy: true,
            //         })
            //     }
            // })

            if (record.images_attributes.length === 0) {
                delete record.images_attributes
            }

            // Prepare links (remove blank items)
            // record.quick_links = this.attributes.quick_links.filter(
                // entry => entry.url && entry.url.length !== 0)
            record.green_statement_links = this.attributes.green_statement_links.filter(
                entry => (entry.url && entry.url.length !== 0)
                || (entry.title && entry.title.length !== 0))
            record.product_links = this.attributes.product_links.filter(
                entry => (entry.url && entry.url.length !== 0)
                || (entry.title && entry.title.length !== 0))
            record.latest_products_links = this.attributes.latest_products_links.filter(
                entry => (entry.url && entry.url.length !== 0)
                || (entry.title && entry.title.length !== 0))

            // CSI Sub-Divisions
            record.csi_sub_divisions_attributes = []
            // this.attributes.csi_sub_divisions.forEach(subDivision => {
            //     record.csi_sub_divisions_attributes.push({
            //         id: subDivision.id || undefined,
            //         csi_index_entry_id: subDivision.csi_index_entry.id,
            //     })
            // })
            this.profileCsiSubDivisions.forEach(subDivision => {
                record.csi_sub_divisions_attributes.push({
                    id: subDivision.id || undefined,
                    csi_index_entry_id: subDivision.csiIndexEntry.id,
                })
            })
            
            this.originalAttributes.csi_sub_divisions.forEach(subDivision => {
                const exists = this.profileCsiSubDivisions.find(fs => fs.csiIndexEntry.id === subDivision.csi_index_entry.id)
                if (!exists) {
                    record.csi_sub_divisions_attributes.push({
                        id: subDivision.id,
                        csi_index_entry_id: subDivision.csi_index_entry.id,
                        _destroy: true,
                    })
                }
            })
            if (record.csi_sub_divisions_attributes.length === 0) {
                delete record.csi_sub_divisions_attributes
            }


            if(record.csi_sub_divisions_attributes && record.csi_sub_divisions_attributes.find(f => f._destroy)){
                record.csi_sub_divisions_attributes.filter(f => f._destroy).forEach(subDivision => {
                    if(record.images_attributes.find(f => f.csi_index_entry_id === subDivision.csi_index_entry_id)){
                        let image = record.images_attributes.find(f => f.csi_index_entry_id === subDivision.csi_index_entry_id && !f._destroy )
                        let imageIndex = record.images_attributes.indexOf(image)
                        if (imageIndex !== -1) {
                            record.images_attributes.splice(imageIndex, 1, {...image, _destroy: true})
                        }
                    }
                })
            }
     
            if (record.primary_csi_division){
                record.primary_csi_division = this.attributes.primary_csi_division.value.id
            }

            if (!record.div && this.attributes.csi_sub_divisions.length > 0) {
                const code = this.attributes.csi_sub_divisions[0].csi_index_entry.code
                record.div = Number(code.split(" ")[0])
            }else if(record.div){
                record.div = record.div.value
            }

            if(record.csi_sub_divisions_attributes){
                let images_with_cover = []
                images_with_cover = record.images_attributes && record.images_attributes.filter(image => image.kind == 'cover');
                let csi_sub_divisions_not_present = record.csi_sub_divisions_attributes.filter((sub_division, sub_division_index, arr)=>{
                     if(sub_division_index+1 >= 16 && (this.originalAttributes.status == 1 || this.originalAttributes.profile_type == '1-premium' || this.originalAttributes.plan == 'Premium' || this.originalAttributes.plan == 'premium')){
                        return false
                     }else{
                        return images_with_cover ? images_with_cover.filter(image => image.csi_index_entry_id == sub_division.csi_index_entry_id ).length == 0 : true
                     }
                }).map(image=>image.csi_index_entry_id);
                let need_to_fill_sub_divisions = this.attributes.csi_sub_divisions.filter(csi => csi.csi_index_entry.entry_type !== 'primary' && csi_sub_divisions_not_present.includes(csi.csi_index_entry.id) ).map(m=>m.csi_index_entry.title)


                // if(this.getCsiSubDivisionCountOnPlan() < need_to_fill_sub_divisions.length){
                //     this.$bvToast.toast(`You can add ${this.getCsiSubDivisionCountOnPlan() < 0 ? 0 : this.getCsiSubDivisionCountOnPlan()} only in ${this.planName()} but you have added ${need_to_fill_sub_divisions.length} CSI sub divisions`, {
                //             title: "Required images to save",
                //             variant: "danger",
                //             autoHideDelay: 5000
                //         })
                //     return
                // }

                // TODO uncomment
                // if(need_to_fill_sub_divisions.length > 0){
                //     this.$bvToast.toast(`please add images for CSI sub divisions: ${need_to_fill_sub_divisions.join(', ')}`, {
                //             title: "Required images to save",
                //             variant: "danger",
                //             autoHideDelay: 5000
                //         })
                //     return
                // }
            }
            // record.keyword_list = (this.attributes.keyword_list || []).join(", ")
            // Object.keys(record.images).forEach(key => obj[key] === undefined || obj[key] === null ? delete obj[key] : {});

            console.log("Record to save:")
            console.dir(record)

            saveManufacturer(this.adminToken, record)
                .then(newRecord => {
                    console.log('newRecord==========>>>>>>>>>>',newRecord);
                    const isNewRecord = !record.id
                    this.applyRecord(newRecord)
                    if (isNewRecord) {
                        this.$router.push({
                            name: "admin-edit-manufacturer",
                            params: { id: newRecord.id }
                        })
                    } else {
                        this.$bvToast.toast("Succeeded!", {
                            title: "Save status",
                            variant: "success",
                            autoHideDelay: 5000
                        })
                    }
                })
                .catch(error => {
                    this.$bvToast.toast(error.message, {
                        title: "Unable to save",
                        variant: "danger",
                        autoHideDelay: 5000
                    })
                })
        },

        onClickEmailAdmin() {
            this.$bvModal.show('send-admin-email')
        },

        async onClickSendAdminEmail() {
            try {
                const response = await fetch(`/api/v1/admin/manufacturers/${this.$route.params.id}/send_admin_email`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ message: this.adminEmailMessage })
                });

                if (response.ok) {
                  this.adminEmailMessage = ''
                  this.$bvModal.hide('send-admin-email')
                } else {
                  alert('Message not delivered') 
                }
            } catch (error) {
                console.log(error)
                alert('Message not delivered')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .collapsed > .justify-content-start > .when-opened,
    :not(.collapsed) > .justify-content-start > .when-closed {
            display: none;
    }
    .link_row {
        & + .link_row {
            margin-top: 8px;
        }
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .available-sub-divisions {
        margin-bottom: 16px;
    }

    .available-sub-division {
        display: flex;
        flex-direction: row;
        background-color: rgba(255, 193, 7, 0.1);
        margin-top: 2px;
        border-radius: 3px;
        padding: 4px 0;

        .action {
            flex: 0;
            padding: 0 6px 2px;
            margin: 4px 8px 4px 0;
            line-height: 0;
        }

        .title {
            display: inline-block;
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .sub-division {
        padding-top: 4px;
        padding-bottom: 4px;

        & + .sub-division {
            border-top: 1px solid #ccc;
        }
    }
</style>
