import { getRequest, postRequest } from "./base"

export const suggestManufacturers = (query) => {
    return getRequest("/manufacturers/suggest", { query })
}

export const getManufacturerFilters = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/manufacturers/filters", parameters)
}

export const getManufacturers = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/manufacturers", parameters)
}

export const getManufacturerDirectory = (directoryType, options = {}) => {
    const parameters = { ...options }
    return getRequest(`/manufacturers/directory/${ directoryType }`, parameters)
}

export const getCsiIndexEntries = () => {
    return getRequest("/csi_entries", { level: 1 })
}

export const getManufacturerKeywords = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/manufacturers/keywords", parameters)
}

export const getManufacturerProductTypes = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/manufacturers/product_types", parameters)
}

export const getManufacturerSubdivisions = (options = {}) => {
    const parameters = { ...options }
    return getRequest("/manufacturers/subdivisions", parameters)
}

export const manufacturerRequestInfo = (profile, request) => {
    if (!profile || typeof profile.id !== "number") {
        console.error(typeof profile.id)
        return Promise.reject(new Error("Invalid profile or `id` is not defined."))
    }
    return postRequest(`/manufacturers/${ profile.id }/request_info`, { request })
}

export const manufacturerRequestQuote = (profile, request) => {
    if (!profile || typeof profile.id !== "number") {
        console.error(typeof profile.id)
        return Promise.reject(new Error("Invalid profile or `id` is not defined."))
    }
    return postRequest(`/manufacturers/${ profile.id }/request_quote`, { request })
}

export const manufacturerRequestSample = (profile, request) => {
    if (!profile || typeof profile.id !== "number") {
        return Promise.reject(new Error("Invalid profile or `id` is not defined."))
    }
    return postRequest(`/manufacturers/${ profile.id }/request_sample`, { request })
}

export const manufacturerRequestEmailProfile = ({profile, email, comment, user_id, profile_url}) => {
    return getRequest(`/manufacturers/${ profile.id }/send_profile`, { email, profile_url, comment, user_id })
}

// export const getSpecializations = () => {
//     return getRequest("/specializations", {})
// }

// OLD

export const manufacturer = ({ id }) => {
    return getRequest(`/manufacturers/${id}`)
}

export const manufacturers = ({ parent, primary, secondary, tertiary, product, keyword, location, greenProducts } = {}) => {
    return getRequest("/manufacturers", {
        parent, primary, secondary, tertiary, product, keyword, location, green_products: greenProducts
    })
}

export const manufacturersDirectoryByName = (letter) => {
    if (letter) {
        return getRequest("/manufacturers_directory/manufacturers", { letter })
    }
    return getRequest("/manufacturers_directory/manufacturers")
}

export const manufacturersDirectoryByIndex = () => {
    return getRequest("/manufacturers_directory/master_format")
}
