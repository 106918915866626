import { getRequest, patchRequest, postRequest, deleteRequest } from "./../base"

export const getManufacturers = (token, { page = 1, per_page = 100, sortBy = "", sortDesc = false, approvalState = undefined, query } = {}) => {
    let params = { page, per_page }
    if (approvalState) {
        params["approval_state"] = approvalState
    }
    if (sortBy) {
        params["order"] = sortBy
        params["direction"] = sortDesc ? "desc" : "asc"
    }else{
        params["order"] = 'created_at'
        params["direction"] = 'desc'
    }
    if (query) {
        params["query"] = query
    }
    return getRequest("/admin/manufacturers", params, {
        "Authorization": `Bearer ${ token }`
    })
}

export const getManufacturer = (token, { id }) => {
    return getRequest(`/admin/manufacturers/${ id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const saveManufacturer = (token, manufacturer) => {
    if (manufacturer.id) {
        let { id, ...manufacturerWithoutId } = manufacturer
        return patchRequest(`/admin/manufacturers/${ id }`, {
            manufacturer_profile: manufacturerWithoutId
        }, {
            "Authorization": `Bearer ${ token }`
        })
    } else {
        return postRequest("/admin/manufacturers", {
            manufacturer_profile: manufacturer
        }, {
            "Authorization": `Bearer ${ token }`
        })
    }
}

export const deleteManufacturer = (token, manufacturer) => {
    console.log("manufacturer.id",manufacturer);
    return deleteRequest(`/admin/manufacturers/${ manufacturer.id }`, null, {
        "Authorization": `Bearer ${ token }`
    })
}

export const approvalStates = (token) => {
    return getRequest("/admin/manufacturers/approval_states", null, {
        "Authorization": `Bearer ${ token }`
    })
}
