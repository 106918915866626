import axios from "axios"
import { signin, signup, adminSignin, upgradeUser, upgradeUserTo, upgradeUserToWithPromoCode, changePassword, sendResetPassword, resetPassword, adminChangePassword, adminSendResetPassword, adminResetPassword, refreshUser } from "api/auth"
// import { createUserFirm, createUserManufacturer } from "api/applications"
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,

    state: (state => {
        const token = localStorage.getItem("_apiToken")
        const userJson = localStorage.getItem("_currentUser")
        if (token && userJson) {
            try {
                const user = JSON.parse(userJson)
                state.token = token
                state.currentUser = user                
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            } catch (error) {
                // Ignore saved state
            }
        }
        const adminToken = localStorage.getItem("_adminApiToken")
        if (adminToken) {
            state.adminToken = adminToken
        }
        return state
    })({
        token: null,
        adminToken: null,
        currentUser: null,
        filter: {
            location: null,
            query: null,
            radius: 30,
        },
    }),

    getters: {
        getField,
        isAuthorized: (state) => !!state.token,
        isAdminAuthorized: (state) => !!state.adminToken,
        currentUser: (state) => state.currentUser,
        adminToken: (state) => state.adminToken,
    },

    mutations: {
        updateField,
        setToken (state, token) {
            if (token) {
                localStorage.setItem("_apiToken", token)
                axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            } else {
                localStorage.removeItem("_apiToken")
                axios.defaults.headers.common["Authorization"] = null
            }
            state.token = token
        },

        setUser (state, user) {
            if (Object.keys(user).length === 0 && user.constructor === Object) {
                localStorage.removeItem("_currentUser")
            } else {
                localStorage.setItem("_currentUser", JSON.stringify(user))
            }
            state.currentUser = { ...user }
        },

        setAdminToken (state, token) {
            if (token) {
                localStorage.setItem("_adminApiToken", token)
            } else {
                localStorage.removeItem("_adminApiToken")
            }
            state.adminToken = token
        },
    },

    actions: {
        resetPassword ({ commit }, { user_id, passwordResetToken, password, passwordConfirmation }) {
            return resetPassword({ user_id, passwordResetToken, password, passwordConfirmation })
                .then(data => {
                    commit("setToken", null)
                    commit("setUser", {})
                    return Promise.resolve(true)
                })
        },

        sendResetPassword ({ commit }, { email }) {
            return sendResetPassword({ email })
                .then(data => {
                    commit("setToken", null)
                    commit("setUser", {})
                    return Promise.resolve(true)
                })
        },

        changePassword ({ commit }, { email, currentPassword, password, passwordConfirmation }) {
            return changePassword({ email, currentPassword, password, passwordConfirmation })
                .then(data => {
                    const { token, user } = data
                    commit("setToken", token)
                    commit("setUser", user)
                    return user
                })
        },

        refreshUser ({ commit }, { id }){
            return refreshUser({ id })
                .then(data => {
                    const { user } = data
                    commit("setUser", user)
                    return user
                })
        },

        signin ({ commit }, { email, password }) {
            return signin({ email, password })
                .then(data => {
                    const { token, user } = data
                    commit("setToken", token)
                    commit("setUser", user)
                    return user
                })
        },

        signup ({ commit }, user) {
            return signup(user)
                .then(data => {
                    const { token, user } = data
                    commit("setToken", token)
                    commit("setUser", user)
                    return user
                })
        },

        signout ({ commit }) {
            commit("setToken", null)
            commit("setUser", {})
            return Promise.resolve(true)
        },

        // createFirmApplication ({ commit }, attributes) {
        //     return createUserFirm(attributes)
        //         .then(data => {
        //             const { token, user } = data
        //             commit("setToken", token)
        //             commit("setUser", user)
        //             return user
        //         })
        // },

        // createManufacturerApplication ({ commit }, attributes) {
        //     return createUserManufacturer(attributes)
        //         .then(data => {
        //             const { token, user } = data
        //             commit("setToken", token)
        //             commit("setUser", user)
        //             return user
        //         })
        // },

        // TODO: Temporary implementation, simply sets plan to "standard"
        upgrade ({ commit }) {
            return upgradeUser(
            ).then(data => {
                const { user } = data
                commit("setUser", user)
                return data.user
            })
        },

        upgradeTo ({ commit }, priceId) {
            return upgradeUserTo(
                priceId
            ).then(data => {
                const { user } = data
                commit("setUser", user)
                return data.user
            })
        },

        upgradeToWithPromoCode ({ commit }, { priceId, promoCode }) {
            return upgradeUserToWithPromoCode({priceId, promoCode}).then(data => {
                const { user } = data
                commit("setUser", user)
                return data.user
            })
        },

        adminSignin ({ commit }, { email, password }) {
            return adminSignin({ email, password })
                .then(data => {
                    const { token } = data
                    commit("setAdminToken", token)
                    return true
                })
        },

        adminResetPassword ({ commit }, { admin_id, passwordResetToken, password, passwordConfirmation }) {
            return adminResetPassword({ admin_id, passwordResetToken, password, passwordConfirmation })
                .then(data => {
                    commit("setToken", null)
                    commit("setUser", {})
                    return Promise.resolve(true)
                })
        },

        adminSendResetPassword ({ commit }, { email }) {
            return adminSendResetPassword({ email })
                .then(data => {
                    commit("setToken", null)
                    commit("setUser", {})
                    return Promise.resolve(true)
                })
        },

        adminChangePassword ({ commit }, { email, currentPassword, password, passwordConfirmation }) {
            return adminChangePassword({ email, currentPassword, password, passwordConfirmation })
                .then(data => {
                    const { token } = data
                    commit("setAdminToken", token)
                    return true
                })
        },

        adminSignout ({ commit }) {
            commit("setAdminToken", null)
        },
    }    
}
